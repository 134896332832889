import * as React from "react"
import PropTypes from "prop-types"
import "./layout.scss"
import Header from "./header"
import { Footer } from "./footer"
import { VisualEditing } from "./visualEditing"
import { Script } from "gatsby"

export const Layout = props => {
  const { children } = props

  return (
    <div className="container-fluid p-0">
      <VisualEditing {...props} />
      <Header />
      <main>{children}</main>
      <Footer />

      {/* <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GTAG}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript> */}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
