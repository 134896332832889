import React, { useState, useEffect } from "react"
import {
  Snackbar,
  SnackbarContent,
  Button,
  Box,
  Typography,
} from "@mui/material"
import { useLocation } from "@reach/router" // Helps track location for analytics
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { useCookieConsent } from "../utils/useCookieConsent"

export const CookieBanner = ({ debug }) => {
  const { cookieAccept, cookieDecline, letOpen } = useCookieConsent()
  const location = useLocation()
  const [open, setOpen] = useState(letOpen)

  useEffect(() => {
    const consentGiven = localStorage.getItem("cookieConsent")

    if (typeof window !== "undefined") {
      // Ensure window.gtag exists before using it
      if (!window.gtag) {
        window.dataLayer = window.dataLayer || []
        window.gtag = function () {
          window.dataLayer.push(arguments)
        }

        // Set initial consent mode (before user accepts/declines)
        window.gtag("consent", "default", {
          ad_storage: "denied",
          analytics_storage: "denied",
          functionality_storage: "denied",
          personalization_storage: "denied",
          security_storage: "granted", // Security cookies always enabled
        })
      }

      // Ensure Gatsby GDPR cookies are set
      if (!consentGiven) {
        setOpen(true)

        if (window.setGatsbyGdprCookies) {
          window.setGatsbyGdprCookies({
            googleAnalytics: false,
            googleTagManager: false,
            facebookPixel: false,
            hotjar: false,
          })
        }
      }
    }
  }, [])

  const handleDecline = () => {
    console.log("Cookies Declined")
    setOpen(false)
    cookieDecline()

    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("consent", "update", {
        ad_storage: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "granted",
      })
    }

    if (typeof window !== "undefined" && window.setGatsbyGdprCookies) {
      window.setGatsbyGdprCookies({
        googleAnalytics: false,
        googleTagManager: false,
        facebookPixel: false,
        hotjar: false,
      })
    }

    // Store user choice in localStorage
    localStorage.setItem("cookieConsent", "denied")
  }

  const handleAccept = () => {
    setOpen(false)
    cookieAccept()

    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("consent", "update", {
        ad_storage: "granted",
        analytics_storage: "granted",
        functionality_storage: "granted",
        personalization_storage: "granted",
        security_storage: "granted",
      })
    }

    if (typeof window !== "undefined" && window.setGatsbyGdprCookies) {
      window.setGatsbyGdprCookies({
        googleAnalytics: true,
        googleTagManager: true,
        facebookPixel: true,
        hotjar: true,
      })
    }

    if (typeof initializeAndTrack === "function") {
      initializeAndTrack(location)
    }

    // Store user choice in localStorage
    localStorage.setItem("cookieConsent", "granted")
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return

    // Ensure default denial if the user closes the banner without choosing
    handleDecline()
  }

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleClose}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "tertiary.main",
            py: 3,
            px: 6,
            color: "white",
          },
        }}
      >
        <SnackbarContent
          sx={{
            backgroundColor: "secondary.main",
            color: "white",
          }}
          message={
            <Box>
              <Typography variant="body2">
                We use cookies on our website to make your experience better and
                to help us monitor and improve our customer service.
              </Typography>
              <Typography variant="body2">
                By continuing, we will assume that you are happy to receive all
                cookies. You can manage the use of cookies through your browser.
              </Typography>
            </Box>
          }
          action={
            <Box
              sx={{
                display: "flex",
                columnGap: 2,
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={handleDecline}
              >
                Decline
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleAccept}
              >
                Accept
              </Button>
            </Box>
          }
        />
      </Snackbar>
    </>
  )
}
